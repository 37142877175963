import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import {Container, Grid} from "semantic-ui-react";
import Link from 'gatsby-link';
import logo from './img/logo.png'
const Header = ({ data }) => (
  <header>
    
    <Container fluid>
    <Grid>
    {console.log(data)}
    
      <Grid.Column style={{padding:'0'}} computer={4} className="mobile hidden tablet hidden">
      <Link to={'/'}><img alt='Logo Poco a poco' style={{padding:'3em 0', width:'100%'}} src={logo} size='small' /></Link>
      </Grid.Column>
      <Grid.Column mobile={2} tablet={2} className=" mobile only tablet only">
      <Link to={'/'}><img alt='Logo Poco a poco' style={{margin:'2em 0px'}} src={logo} size='small' /></Link>
      </Grid.Column>
      <Grid.Column mobile={14} tablet={14} className=" mobile only tablet only">
      <Link to={'/'}><img alt='Logo Poco a poco' src={logo} size='small' /></Link>
      </Grid.Column>
      <Grid.Column computer={12} className="mobile hidden tablet hidden">
      </Grid.Column>
      <Grid.Column style={{padding:'0px'}} computer={4} className="border mobile hidden tablet hidden nav-menu">
      <Link className={data.slug === 'manifiesto' ? 'active' : 'unactive'} to={'/manifiesto/'}><p style={{padding: '1em'}} className="center">Manifiesto</p></Link>
      </Grid.Column>
      <Grid.Column style={{padding:'0px'}} computer={4} className="border mobile hidden tablet hidden">
      <Link className={data.slug === 'intereses' ? 'active' : 'unactive'} to={'/intereses/'}><p style={{padding: '1em'}} className="center">Intereses</p></Link>
      </Grid.Column>
      <Grid.Column style={{padding:'0px'}} computer={4} className="border mobile hidden tablet hidden">
      <Link className={data.slug === 'recursos' ? 'active' : 'unactive'} to={'/recursos/'}><p style={{padding: '1em'}} className="center">Recursos</p></Link>
      </Grid.Column>
      <Grid.Column style={{padding:'0px'}} computer={4} className="border mobile hidden tablet hidden">
      <Link className={data.slug === 'contact' ? 'active' : 'unactive'} to={'/contact/'}><p style={{padding: '1em'}} className="center">Contacto</p></Link>
      </Grid.Column>  
    </Grid>
  </Container>
  </header>
  
)

export default function MyHeader(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              logo
            }
          }
        }
      `}
      render={data => <Header data={data} {...props} />}
    />
  )
}

Header.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}