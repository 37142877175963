import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import {Container, Grid} from "semantic-ui-react";
import logo from './img/footer.png';
const Footer = ({ data }) => (
  
  <footer style={{backgroundColor: '#000', marginTop:'3em'}}>
    <Container fluid>
    <Grid className="border">
    <Grid.Column computer={1} className=" mobile hidden tablet hidden">
      
      </Grid.Column>
      <Grid.Column computer={3} className=" mobile hidden tablet hidden">
      <img style={{margin:'2em auto', width: '200px'}} src={logo} size='small' alt="Logo Poco a poco" />
      </Grid.Column>
      <Grid.Column computer={4} className=" mobile hidden tablet hidden">
        <p style={{color:'#fff'}}>POCO A POCO</p>
      </Grid.Column>
      <Grid.Column computer={4} className=" mobile hidden tablet hidden">
      <p style={{color:'#fff'}}>UNETE A LA COMUNIDAD</p>
      </Grid.Column>
      <Grid.Column computer={3} className=" mobile hidden tablet hidden">
      <p style={{color:'#fff'}}>Newasletter</p>
      </Grid.Column>
      <Grid.Column computer={1} className=" mobile hidden tablet hidden">
      
      </Grid.Column>
    </Grid>
  </Container>
  </footer>
  
)

export default function MyFooter(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              logo
            }
          }
        }
      `}
      render={data => <Footer data={data} {...props} />}
    />
  )
}

Footer.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}